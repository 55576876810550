// TablePrefsService.js
import { config } from "../config";
import apiClient from "../queries/apiClient"; // your custom axios or fetch wrapper

/**************************************
 * GET all table pref sets
 * -> GET ${config.REACT_APP_BACKEND_HOST}/get-available-table-pref-sets
 **************************************/
export async function getAvailableTablePrefSets() {
  try {
    const { data } = await apiClient.get(
      `${config.REACT_APP_BACKEND_HOST}-table-prefs/get-available-table-pref-sets`,
      {
      }
    );
    return data; // e.g. { message, items, count, ... }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to fetch table pref sets.";
    throw new Error(msg);
  }
}

/**************************************
 * GET single table pref set by tableId
 * -> GET ${config.REACT_APP_BACKEND_HOST}/get-table-pref-set
 *
 * Your server code expects a JSON body with { tableId }
 * but some frameworks don't allow GET + request body.
 * If your server is strictly "GET" with a body, keep this style.
 **************************************/
export async function getSingleTablePrefSet(tableId, prefSetId) {
  try {
    const { data } = await apiClient.get(
      `${config.REACT_APP_BACKEND_HOST}-table-prefs/get-table-pref-set`,
      {
        // Pass the JSON body as "body" in the axios config (unusual, but matching your pattern)
        params: { tableId,
        prefSetId},
      }
    );
    return data; // { message, item }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to fetch single table pref set.";
    throw new Error(msg);
  }
}

/**************************************
 * CREATE table pref set
 * -> POST ${config.REACT_APP_BACKEND_HOST}/create-table-pref-set
 **************************************/
export async function createTablePrefSet(payload) {
  // payload = { tableId, preferences, emailSchedule }
  try {
    const { data } = await apiClient.post(
      `${config.REACT_APP_BACKEND_HOST}-table-prefs/create-table-pref-set`,
      {
        ...payload,
      },
    );
    return data; // { message, item }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to create table pref set.";
    throw new Error(msg);
  }
}

/**************************************
 * UPDATE table pref set
 * -> PUT ${config.REACT_APP_BACKEND_HOST}/update-table-pref-set
 **************************************/
export async function updateTablePrefSet(payload) {
  // payload = { tableId, preferences?, emailSchedule? }
  try {
    const { data } = await apiClient.put(
      `${config.REACT_APP_BACKEND_HOST}-table-prefs/update-table-pref-set`,
      {
        body: JSON.stringify(payload),
      },
    );
    return data; // { message, updatedItem }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to update table pref set.";
    throw new Error(msg);
  }
}

/**************************************
 * DELETE table pref set
 * -> DELETE ${config.REACT_APP_BACKEND_HOST}/delete-table-pref-set
 **************************************/
export async function deleteTablePrefSet(tableId, prefSetId) {
  try {
    const { data } = await apiClient.delete(
      `${config.REACT_APP_BACKEND_HOST}-table-prefs/delete-table-pref-set`,
      {
        data: { tableId, prefSetId },  // <-- Put them in `data`, not `params`
        // or data: JSON.stringify({ tableId, prefSetId }) if you need a raw string},
      }
    );
    return data;
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to delete table pref set.";
    throw new Error(msg);
  }
}
