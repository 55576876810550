// TablePrefsService.js
import { config } from "../config";
import apiClient from "../queries/apiClient"; // your custom axios or fetch wrapper

/**************************************
 * GET all table pref sets
 * -> GET ${config.REACT_APP_BACKEND_HOST}/get-available-table-pref-sets
 **************************************/
export async function getAvailableTablePrefSets(token) {
  try {
    const { data } = await apiClient.get(
      `${config.REACT_APP_BACKEND_HOST}/get-available-table-pref-sets`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data; // e.g. { message, items, count, ... }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to fetch table pref sets.";
    throw new Error(msg);
  }
}

/**************************************
 * GET single table pref set by tableId
 * -> GET ${config.REACT_APP_BACKEND_HOST}/get-table-pref-set
 *
 * Your server code expects a JSON body with { tableId }
 * but some frameworks don't allow GET + request body.
 * If your server is strictly "GET" with a body, keep this style.
 **************************************/
export async function getSingleTablePrefSet(token, tableId) {
  try {
    const { data } = await apiClient.get(
      `${config.REACT_APP_BACKEND_HOST}/get-table-pref-set`,
      {
        headers: {
          Authorization: token,
        },
        // Pass the JSON body as "body" in the axios config (unusual, but matching your pattern)
        body: JSON.stringify({ tableId }),
      }
    );
    return data; // { message, item }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to fetch single table pref set.";
    throw new Error(msg);
  }
}

/**************************************
 * CREATE table pref set
 * -> POST ${config.REACT_APP_BACKEND_HOST}/create-table-pref-set
 **************************************/
export async function createTablePrefSet(token, payload) {
  // payload = { tableId, preferences, emailSchedule }
  try {
    const { data } = await apiClient.post(
      `${config.REACT_APP_BACKEND_HOST}/create-table-pref-set`,
      {
        body: JSON.stringify(payload),
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data; // { message, item }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to create table pref set.";
    throw new Error(msg);
  }
}

/**************************************
 * UPDATE table pref set
 * -> PUT ${config.REACT_APP_BACKEND_HOST}/update-table-pref-set
 **************************************/
export async function updateTablePrefSet(token, payload) {
  // payload = { tableId, preferences?, emailSchedule? }
  try {
    const { data } = await apiClient.put(
      `${config.REACT_APP_BACKEND_HOST}/update-table-pref-set`,
      {
        body: JSON.stringify(payload),
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data; // { message, updatedItem }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to update table pref set.";
    throw new Error(msg);
  }
}

/**************************************
 * DELETE table pref set
 * -> DELETE ${config.REACT_APP_BACKEND_HOST}/delete-table-pref-set
 **************************************/
export async function deleteTablePrefSet(token, tableId) {
  try {
    const { data } = await apiClient.delete(
      `${config.REACT_APP_BACKEND_HOST}/delete-table-pref-set`,
      {
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({ tableId }),
      }
    );
    return data; // { message, tableId, etc. }
  } catch (err) {
    const msg = err?.response?.data?.error || err.message || "Failed to delete table pref set.";
    throw new Error(msg);
  }
}
