import React from 'react'
import './IconStyles.css';
import {RiCustomerServiceLine} from "react-icons/ri";

export const AccountManagementIcon = (props) => (
   <div style={{minWidth: "1.75em"}}>
    <RiCustomerServiceLine size={props.size ? props.size : "1.25em"}
                       color={props.color ? props.color : undefined}
    />
   </div>
)